<template>
  <m-infinite-scroll
    :load="load"
    :pagination="pagination"
    icon="receipt"
    resource="bookings"
  >
    <div class="row q-col-gutter-md">
      <div
        v-for="item in items"
        :key="item.reference"
        class="col-md-4"
      >
        <booking-card
          v-bind="item"
          :user="item.user.data"
          :starts-at="item.starts_at"
        />
      </div>
    </div>
  </m-infinite-scroll>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { bookings } from 'api/user'
import { bookings as orgBookings } from 'api/organisations'
import bookingCard from './booking-card'
import authentication from 'mixins/authentication'
import { MInfiniteScroll } from 'components/'

export default {
  name: 'Bookings',
  components: {
    bookingCard,
    MInfiniteScroll
  },
  mixins: [authentication],
  props: {
    bookingsUser: String,
    bookingsOrg: String
  },
  data () {
    return {
      items: [],
      pagination: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    success (res) {
      this.pagination = res.meta.pagination
      this.items = this.items.concat(res.data)
    },
    error () {
      throw Error
    },
    load (index) {
      return this.bookingsOrg
        ? orgBookings(this.bookingsOrg, { page: index })
          .then(this.success)
          .catch(this.error)

        : bookings(this.bookingsUser ? this.bookingsUser : this.user.username, { page: index, limit: 15, include: 'user' })
          .then(this.success)
          .catch(this.error)
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 1024px
.col-md-4
  padding 8px 0 0 8px
  max-width 100%
  @media (min-width 768px)
    width 33%
</style>
