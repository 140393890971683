<template>
  <q-card class="relative-position shadow-transition hoverable">
    <router-link :to="bookingLink(reference)">
      <m-image
        v-if="background"
        :src="background"
        width="100%"
        height="120px"
      />
      <div
        v-else
        class="unknownBackground"
      />
      <m-travel-icon
        :type="type"
        color="white"
        :bg-color="getTravelContentHex(type)"
        circle
        size="56px"
        class="absolute icon-type"
        :class="{'is-cancelled': status === 'CANCELLED'}"
      />
      <q-card-section
        class="bg-white"
        style="padding-top: 14px; margin-top: 28px"
      >
        <q-item-label caption>
          {{ status }}
        </q-item-label>
        <q-item-label label class="ellipsis">
          {{ name ? name : $t('no_name_for_booking') }}
        </q-item-label>
        <q-item-label caption>
          {{ info }}<br>
          <template v-if="startsAt">
            {{ $t('date.starts_at', { date: shortDate(startsAt.date) }) }}
          </template>

          <div v-if="canSeeAdditionalInfo" class="bo-info">
            {{ $t('traveller_with_name', { name: user.meta.first_name + ' ' + user.meta.last_name }) }}
          </div>
        </q-item-label>
      </q-card-section>
    </router-link>
    <template v-if="!hasStarted() && status !== 'CANCELLED'">
      <q-separator />
      <q-card-actions style="min-height:52px" align="right">
        <q-btn
          v-if="!hasStarted() && canBeAmended()"
          flat
          :to="{
            query: {
              type:'amendment',
              booking: reference
            },
            hash: '/concierge'
          }"
        >
          {{ $t('amend') }}
        </q-btn>
        <q-btn
          v-if="!hasStarted() && canBeCancelled()"
          flat
          @click.stop="$store.dispatch('entities/bookings/cancel', reference)"
        >
          {{ $t('cancel') }}
        </q-btn>
      </q-card-actions>
    </template>
  </q-card>
</template>

<script type="text/javascript">
import authentication from '../../mixins/authentication'
import travelContents from 'mixins/travelContents'
import dateUtil from 'utils/date-time.js'
import { MImage, MTravelIcon } from 'components/'

export default {
  components: {
    MImage,
    MTravelIcon
  },
  mixins: [travelContents, authentication],
  props: ['name', 'info', 'type', 'reference', 'price', 'user', 'startsAt', 'status'],
  computed: {
    background () {
      let type = this.getTravelContentBackground(this.type)
      return type ? require(`assets/${type}`) : null
    },
    canSeeAdditionalInfo () {
      return this.hasRole('backoffice') || this.hasRole('admin')
    }
  },
  methods: {
    bookingLink (ref) {
      return this.hasPermission('organisations.bookings.index')
        ? { query: { booking: ref }, hash: '/booking' }
        : `/bookings/${ref}`
    },
    hasStarted () {
      const today = dateUtil.newDate()

      const untilStart = dateUtil.getDateDiff(today, this.startsAt.date)

      return untilStart > 0
    },
    canBeCancelled () {
      const cannotBeCancelledTypes = ['drt']

      return !cannotBeCancelledTypes.includes(this.type)
    },
    canBeAmended () {
      const cannotBeAmendedTypes = ['drt']

      return !cannotBeAmendedTypes.includes(this.type)
    },
    shortDate (date) {
      return dateUtil.toShortDate(date)
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card-section-overlay
  background-color transparent
  .q-card-subtitle
    color rgba(0,0,0,0.6)
.col.column
  width 8%
.icon-type
  left 8px
  top 95px

.booking
  width 100%
  overflow: hidden

.unknownBackground
  height 120px
  width 100%
  background-color white

.is-cancelled
  opacity 0.75
  &:after
    content ""
    position absolute
    height 4px
    background-color $grey-8
    outline 4px white solid
    top 50%
    left -12px
    right -12px
    transform translateY(-50%) rotate(45deg)
</style>
